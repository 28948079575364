import { pick } from 'lodash';
import { NFT_SERVICE } from "../../constants";
import Api from "../axios/SetupAxios"

interface SaveTransactionLogReq {
  network: string
  contractAddress: string
  tokenId: string
  marketplaceContractAddress: string
  referralCode: string
}


export const saveTransactionLogApi = async (data: SaveTransactionLogReq): Promise<any> => {
  try {
    const params = pick(data, [
      'network',
      'contractAddress',
      'tokenId',
      'marketplaceContractAddress',
      'referralCode'
    ]);
    const resp = await Api.AuthService.POST(NFT_SERVICE.SAVE_TRANSACTION_LOG, params);
    return resp?.data
  } catch (error) {
    console.error("An error occurred while saving the transaction log:", error);
    throw error
  }
}