import { Box, CircularProgress, Container, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { WalletContext } from '../wallet/walletContext';
import { useAppDispatch } from '../../redux/hook';
import { useIntl } from 'react-intl';
import { fetchUserProfile } from '../../components/ProfilePage/userProfileSlice';
import { useLocation } from 'react-router-dom';
import { SettingSideBar } from '../../components/ProfilePage/SettingSideBar';
import { getAffiliateApi, getAffiliateStatisticsApi, getTimeRewardAffiliateApi, withdrawAffiliateApi } from '../../setup/api/apiAffliate';
import { AffiliateStatisticsType, AffiliateType, ClaimStatus } from '../../models/AffliateType';
import { toast } from 'react-toastify';
import { AFFLIATE_TYPE, SUPPORT_NETWORK } from '../../constants';
import { AffiliateClaimModal } from '../../components/Modal/AffiliateClaimModal';

export const AccountAffliatePage = () => {
    const intl = useIntl();
    const location = useLocation();
    const dispatch = useAppDispatch()
    const { isLogin, tokenExpiredCb, setOpenModalLogin } = useContext(WalletContext);

    const [affiliateList, setAffiliateList] = useState<AffiliateType[]>()
    const [affiliateStatisticsData, setAffiliateStatisticsData] = useState<AffiliateStatisticsType>()

    const [openModalAffiliateClaim, setopenModalAffiliateClaim] = useState(false)
    const [loadingState, setLoadingState] = useState<any>({});
    const [reloadData, setReloadData] = useState(false)

    const handleClaimReward = async ({ year, month }: { year?: number, month?: number }) => {
        const key = `${year}-${month}`;
        setLoadingState((prev: any) => ({ ...prev, [key]: true }));
        try {
            const resp = await withdrawAffiliateApi({ year, month, network: SUPPORT_NETWORK }, tokenExpiredCb)
            if (resp?.data?.status === ClaimStatus?.PENDING) {
                setReloadData(!reloadData)
                setopenModalAffiliateClaim(true)
            } else {
                toast.error('Error handling claim');
            }
        } catch (error) {
            toast.error('Error handling claim');
        } finally {
            setLoadingState((prev: any) => ({ ...prev, [key]: false }));
        }
    }

    useEffect(() => {
        if (isLogin) return;
        if (!isLogin) {
            setOpenModalLogin(true);
            localStorage.setItem('redirectPath', location?.pathname);
        }
    }, [isLogin]);

    useEffect(() => {
        if (!isLogin) return
        dispatch(fetchUserProfile(tokenExpiredCb))
    }, [isLogin])

    useEffect(() => {
        if (!isLogin) return
        (async () => {
            const res = await getAffiliateApi(SUPPORT_NETWORK)
            const res2 = await getAffiliateStatisticsApi(SUPPORT_NETWORK)
            setAffiliateList(res)
            setAffiliateStatisticsData(res2)
        })()
    }, [isLogin, reloadData])

    const [timeRewardAffiliate, setTimeRewardAffiliate] = useState<number>(0)

    useEffect(() => {
        if (!isLogin) return
        (async () => {
            const res = await getTimeRewardAffiliateApi({
                type: AFFLIATE_TYPE?.TIME_DAY,
                isAll: true
            })
            setTimeRewardAffiliate(res?.data?.[0]?.value)
        })()
    }, [isLogin])


    const regex = /^([\d.,]+(?:e-?\d+)?)\s*(\w+)$/i;
    const matchesFloor = affiliateStatisticsData?.totalAffiliateReward ? regex.exec(affiliateStatisticsData?.totalAffiliateReward) : null;
    const numberValueFloor = matchesFloor ? parseFloat(matchesFloor[1]) : 0
    const displayCurrencyaffiliateReward = matchesFloor ? matchesFloor[2] : ''
    const displayValueaffiliateReward = matchesFloor ? (numberValueFloor < 0.001 ? '<0.001' : numberValueFloor.toFixed(3)) : 0


    return (
        <Container h={isLogin ? 'auto' : '100vh'} maxW={{ xxl: 1400, xl: 1200, lg: 960, md: 768, sm: 480, base: 390 }} margin={'0 auto'} p={0}>
            {isLogin && <>
                <Flex w={'100%'} flexWrap={'wrap'} mt={{ base: '16px', lg: '40px' }}>
                    <Box w={{ lg: '25%', base: '100%' }}>
                        <SettingSideBar />
                    </Box>
                    <Box w={{ lg: '75%', base: '100%' }}>
                        <Box pl={{ base: 0, lg: '72px' }}>
                            <Text mt={{ base: '32px', lg: 0 }} mb={{ base: '32px', lg: '48px' }} fontWeight={700} fontSize={{ base: '24px', lg: '32px' }}>{intl.formatMessage({ id: 'SETTING.AFFILIATE.TITLE' })}</Text>
                            <Flex borderRadius={'6px'} justify={'center'} align={'center'} backgroundColor={'#FFFFFF0A'} px={{ base: '24px', lg: '32px' }} py={{ base: '12px', lg: '32px' }} flexWrap={'wrap'}>
                                <Box borderBottom={{ lg: 'none', base: '1px solid #27282A' }} borderRight={{ base: 'none', lg: '1px solid #27282A' }} w={{ lg: '25%', base: '100%' }}>
                                    <Box py={{ base: '16px', lg: 0 }} display={'flex'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
                                        <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={400} fontSize={{ lg: '16px', base: '16px' }} color={'#808489'}>{intl.formatMessage({ id: 'SETTING.AFFILIATE.NUMBER.REFERRALS' })}</Text>
                                        <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={700} fontSize={{ lg: '24px', base: '20px' }}>{affiliateStatisticsData?.numberOfReferrals}</Text>
                                    </Box>
                                </Box>
                                <Box borderBottom={{ lg: 'none', base: '1px solid #27282A' }} borderRight={{ base: 'none', lg: '1px solid #27282A' }} w={{ lg: '25%', base: '100%' }}>
                                    <Box py={{ base: '16px', lg: 0 }} display={'flex'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
                                        <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={400} fontSize={{ lg: '16px', base: '16px' }} color={'#808489'}>{intl.formatMessage({ id: 'SETTING.AFFILIATE.NUMBER.BUYERS' })}</Text>
                                        <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={700} fontSize={{ lg: '24px', base: '20px' }}>{affiliateStatisticsData?.numberOfBuyers}</Text>
                                    </Box>
                                </Box>
                                <Box borderBottom={{ lg: 'none', base: '1px solid #27282A' }} borderRight={{ base: 'none', lg: '1px solid #27282A' }} w={{ lg: '25%', base: '100%' }}>
                                    <Box py={{ base: '16px', lg: 0 }} display={'flex'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
                                        <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={400} fontSize={{ lg: '16px', base: '16px' }} color={'#808489'}>{intl.formatMessage({ id: 'SETTING.AFFILIATE.NUMBER.TOTAL' })}</Text>
                                        <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={700} fontSize={{ lg: '24px', base: '20px' }}>{displayValueaffiliateReward + ' ' + displayCurrencyaffiliateReward}</Text>
                                    </Box>
                                </Box>
                                <Box w={{ lg: '25%', base: '100%' }}>
                                    <Box py={{ base: '16px', lg: 0 }} display={'flex'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
                                        <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={400} fontSize={{ lg: '16px', base: '16px' }} color={'#808489'}>{intl.formatMessage({ id: 'SETTING.AFFILIATE.NUMBER.FEE' })}</Text>
                                        <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={700} fontSize={{ lg: '24px', base: '20px' }}>{affiliateStatisticsData?.currentAffiliateRate}%</Text>
                                    </Box>
                                </Box>
                            </Flex>
                            <Box w={'100%'} mt={{ base: '24px', lg: '40px' }}>
                                <TableContainer>
                                    <Table variant='simple'>
                                        <Thead>
                                            <Tr>
                                                <Th px={{ base: '6px', lg: '16px' }} color={'#515458'} textAlign={'center'} w={{ base: '0%', lg: '25%' }} display={{ base: 'none', lg: 'table-cell' }}>{intl.formatMessage({ id: 'SETTING.AFFILIATE.TABLE.SALESMONTH' })}</Th>
                                                <Th px={{ base: '6px', lg: '16px' }} color={'#515458'} w={{ base: '33%', lg: '25%' }} isNumeric>{intl.formatMessage({ id: 'SETTING.AFFILIATE.TABLE.FEE' })}</Th>
                                                <Th px={{ base: '6px', lg: '16px' }} color={'#515458'} textAlign={'center'} w={{ base: '33%', lg: '25%' }}>{intl.formatMessage({ id: 'SETTING.AFFILIATE.TABLE.WITHDRAWALMONTH' })}</Th>
                                                <Th px={{ base: '6px', lg: '16px' }} color={'#515458'} textAlign={'center'} w={{ base: '33%', lg: '25%' }}>{intl.formatMessage({ id: 'SETTING.AFFILIATE.TABLE.WITHDRAWAL' })}</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {affiliateList && affiliateList?.length > 0 ? affiliateList?.map(((item, i: number) => {
                                                const regex = /^([\d.,]+(?:e-?\d+)?)\s*(\w+)$/i;
                                                const matchesFloor = item?.affiliateFee ? regex.exec(item?.affiliateFee) : null;
                                                const numberValueFloor = matchesFloor ? parseFloat(matchesFloor[1]) : 0
                                                const displayCurrencyaffiliateFee = matchesFloor ? matchesFloor[2] : ''
                                                const displayValueaffiliateFee = matchesFloor ? (numberValueFloor < 0.001 ? '<0.001' : numberValueFloor.toFixed(3)) : ''
                                                return (
                                                    <Tr cursor="pointer" _hover={{ bg: '#FFFFFF0A' }} fontSize={'16px'} key={i}>
                                                        <Td p={{ base: '6px', lg: '16px' }} display={{ base: 'none', lg: 'table-cell' }} textAlign={'center'}>{item?.year + '/' + item?.month}</Td>
                                                        <Td p={{ base: '6px', lg: '16px' }} >
                                                            <Flex justifyContent={'end'}>
                                                                <Text>{displayValueaffiliateFee}</Text>
                                                                <Text fontWeight={700} ml={'5px'} color={'#808489'}>{displayCurrencyaffiliateFee}</Text>
                                                            </Flex>
                                                        </Td>
                                                        <Td p={{ base: '6px', lg: '16px' }} textAlign={'center'}>{item?.withdrawalYear + '/' + item?.withdrawalMonth}</Td>
                                                        <Td p={{ base: '6px', lg: '16px' }} display={'flex'} justifyContent={'center'} >
                                                            <Flex align={'center'} justify={'center'}>
                                                                <Box
                                                                    onClick={(item?.claimStatus !== ClaimStatus?.OPEN || loadingState[`${item.year}-${item.month}`]) ? () => { } : () => handleClaimReward({ year: item?.year, month: item?.month })}
                                                                    as={'button'}
                                                                    color='#FFFFFF'
                                                                    cursor={'pointer'}
                                                                    rounded="6px"
                                                                    fontSize={{ base: '16px', lg: '16px' }}
                                                                    fontWeight='600'
                                                                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                                                                    bg={'linear-gradient(90deg, #29E9DD, #F99FAB)'}
                                                                    p={'2px'}
                                                                    height={{ base: '40px', lg: '40px' }}
                                                                    _hover={{ bg: 'linear-gradient(90deg, #F99FAB, #29E9DD)', transition: 'all 0.2s cubic-bezier(.08,.52,.52,1)' }}
                                                                    _focus={{
                                                                        boxShadow:
                                                                            '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                                                                    }}
                                                                    _active={{
                                                                        bg: '#dddfe2',
                                                                        transform: 'scale(0.98)',
                                                                        borderColor: '#bec3c9',
                                                                    }}
                                                                    disabled={item?.claimStatus === ClaimStatus?.OPEN || item?.claimStatus === ClaimStatus?.CLOSE || item?.claimStatus === ClaimStatus?.PENDING || item?.claimStatus === ClaimStatus?.SUCCEEDED || item?.claimStatus === ClaimStatus?.FAILED || loadingState[`${item.year}-${item.month}`]}
                                                                    opacity={(item?.claimStatus === ClaimStatus?.OPEN || item?.claimStatus === ClaimStatus?.CLOSE || item?.claimStatus === ClaimStatus?.PENDING || item?.claimStatus === ClaimStatus?.SUCCEEDED || item?.claimStatus === ClaimStatus?.FAILED || loadingState[`${item.year}-${item.month}`]) ? '30%' : ''}
                                                                >
                                                                    <Box h={'100%'} bg="#131619" rounded="4px">
                                                                        <Box style={{ textWrap: 'nowrap' }} display='flex' alignItems={'center'} px={{ base: '12px', lg: '24px' }} h={'100%'} textAlign={'center'} bgColor={'#FFFFFF0A'}>
                                                                            {item?.claimStatus === ClaimStatus?.OPEN && intl.formatMessage({ id: 'BUTTON.CLAIM' })}
                                                                            {item?.claimStatus === ClaimStatus?.CLOSE && intl.formatMessage({ id: 'BUTTON.CLAIM.CLOSE' })}
                                                                            {item?.claimStatus === ClaimStatus?.PENDING && intl.formatMessage({ id: 'BUTTON.CLAIM.PENDING' })}
                                                                            {item?.claimStatus === ClaimStatus?.SUCCEEDED && intl.formatMessage({ id: 'BUTTON.CLAIM.SUCCEEDED' })}
                                                                            {item?.claimStatus === ClaimStatus?.FAILED && intl.formatMessage({ id: 'BUTTON.CLAIM.FAILED' })}
                                                                            {loadingState[`${item.year}-${item.month}`] && <CircularProgress pl={'10px'} size={'20px'} isIndeterminate color='green.700' />}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Flex>
                                                        </Td>

                                                    </Tr>)
                                            }
                                            )) :
                                                <Tr>
                                                    <Td colSpan={4} w={'100%'}>
                                                        <Flex align={'center'} justifyContent={'center'}>
                                                            {intl.formatMessage({ id: 'TABLE.NOMATCHING.RECORDFOUND' })}
                                                        </Flex>
                                                    </Td>
                                                </Tr>
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>
                </Flex>
                <AffiliateClaimModal time={timeRewardAffiliate} openModal={openModalAffiliateClaim} onCloseModal={() => setopenModalAffiliateClaim(false)} />
            </>}

        </Container>
    )
}
